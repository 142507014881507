"use client";

import { ROUTES } from "@/constants/routes";
import Globe02Icon from "@/icons/globe-02.svg";
import Menu01Icon from "@/icons/menu-01.svg";
import LogoSvg from "@/logo.svg";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ScrollLink } from "../../shared/ScrollLink";

const links = [
  { title: "How it Works", link: ROUTES.howItWorks },
  { title: "Features", link: ROUTES.features },
  { title: "Blog", link: ROUTES.blogs },
  { title: "FAQ", link: ROUTES.faq },
];

export function Header() {
  const pathname = usePathname();

  return (
    <header
      data-theme={pathname === ROUTES.home ? `dark` : `light`}
      className={`top-0 z-10 w-full bg-transparent py-10 ${pathname === ROUTES.home ? "absolute" : ""}`}>
      <nav className="container-responsive navbar h-[50px] !min-h-fit !py-0">
        <div className="navbar-start">
          {/* logo */}
          <Link href={ROUTES.home} title="Home">
            <LogoSvg className="h-10 w-36 dark:text-white sm:w-48" />
          </Link>
        </div>

        <div className="navbar-center hidden lg:flex">
          <ul className="flex w-full gap-10">
            {links.map((x) => (
              <li key={x.title}>
                <ScrollLink
                  className={`text-center text-base font-normal ${pathname === x.link ? "border-b pb-1" : ""}`}
                  role="button"
                  href={`/#${x.link}`}
                  title={x.title}>
                  {x.title}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </div>

        <div className="navbar-end">
          {/* mobile menu button */}
          <div data-theme="light" className="dropdown dropdown-end bg-transparent lg:hidden">
            <div
              tabIndex={0}
              role="button"
              className="btn h-9 min-h-9 gap-2 rounded-3xl px-3 text-zinc-700 sm:h-12 sm:min-h-12 sm:px-4">
              <span className="text-sm sm:text-base">Menu</span>
              <Menu01Icon className="h-4 w-4 sm:h-5 sm:w-5" />
            </div>
            <ul
              tabIndex={0}
              className="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-base-100 p-2 shadow">
              {links.map((x) => (
                <li key={x.title}>
                  <ScrollLink
                    className={`py-2 ${pathname === x.link && "font-semibold !text-primary"}`}
                    role="button"
                    href={`/#${x.link}`}
                    title={x.title}>
                    {x.title}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </div>
          {/* translate dropdown */}
          <div className="dropdown hidden lg:block">
            <div
              onClick={()=> {
                //@ts-ignore
                document.getElementById("my-drawer22").click();
              }}
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-outline btn-sm h-fit min-h-fit gap-2 rounded-3xl border-2 border-neutral-300 py-1 !text-base font-normal">
              <Globe02Icon className="h-5 w-5" />
              <span>EN</span>
            </div>
            <ul
              data-theme="light"
              tabIndex={0}
              className="menu dropdown-content z-[1] w-32 rounded-box bg-base-100 p-2 shadow">
              {["English", "Arabic"].map((x, i) => (
                <li key={i}>
                  <a>{x}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
