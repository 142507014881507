import Link, { LinkProps } from "next/link";
import { AnchorHTMLAttributes, PropsWithChildren } from "react";

type AnchorProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>;
type ScrollLinkProps = AnchorProps & LinkProps & PropsWithChildren;

export const ScrollLink = ({ children, ...props }: ScrollLinkProps) => {
  const onScroll: LinkProps["onClick"] = (e) => {
    e.preventDefault();
    // remove everything before the hash
    const targetId = e.currentTarget.href.replace(/.*\#/, "");
    const elem = document.getElementById(targetId);
    elem?.scrollIntoView({
      behavior: "smooth",
    });
    // window.scrollTo({
    //   top: elem?.getBoundingClientRect().top,
    //   behavior: "smooth",
    // });
  };

  return (
    <Link {...props} onClick={onScroll}>
      {children}
    </Link>
  );
};
